.feather {
  width: 18px;
  height: 18px;
  stroke-width: 2;
}

.feather-sm {
  width: 14px;
  height: 14px;
}

.feather-lg {
  width: 36px;
  height: 36px;
}
