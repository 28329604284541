.content {
  padding: $content-padding-mobile;
  flex: 1;
  width: 100vw;
  max-width: 100vw;
  direction: ltr;

  @include media-breakpoint-up(md) {
    width: auto;
    max-width: auto;
  }

  @include media-breakpoint-up(lg) {
    padding: $content-padding-desktop;  
  }
}
